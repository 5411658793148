import { Typography, List, ListItem, ListItemText } from '@mui/material';

interface InfoListProps {
  title: string;
  items: (string | JSX.Element)[];
}

export function InfoList({ title, items }: InfoListProps) {
  return (
    <>
      <Typography variant="h6" className="tw-font-semibold tw-mt-8">
        {title}
      </Typography>
      <List dense sx={{ ml: 4, listStyleType: 'disc' }}>
        {items.map((item, index) => (
          <ListItem key={index} sx={{ display: 'list-item' }}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
