import React, { ReactElement, useState } from 'react';
import { Button, Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

interface MailComponentProps {
    children: (handleOpen: () => void) => ReactElement;
    messagePlaceholder?: string;
}

export const ContactDialog: React.FC<MailComponentProps> = (props) => {
    const { user } = useAuth0();
    const loggedInEmail = user?.email;
    const loggedInSub = user?.sub;
    const { messagePlaceholder } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => {
        setIsOpen(false);
        setName('');
        setEmail('');
        setMessage('');
        setMessageError(false);
        setEmailError(false);
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+$/;
        return email === '' || emailRegex.test(email);
    };

    const handleBlurEmail = () => {
        setEmailError(email !== '' && !validateEmail(email));
    };

    const handleBlurMessage = () => {
        setMessageError(message.trim() === '');
    };

    const handleSubmit = async () => {
        if (messageError || emailError) return;

        if (message === '') setMessageError(true);

        const discordWebhookURL = 'https://discord.com/api/webhooks/1151202493548736624/n_D5mStgFcly5n1L52CTY4M347K8mOU4lW22a7Maa5sqikIPmBCQI-Yy59eRWLdyADWb';

        const discordMessage = {
            content: `**New Message from:** ${name} \n**Email:** ${email}\n**Message:** ${message}`
        };

        if (loggedInEmail || loggedInSub) {
            discordMessage.content += `\n**Logged in email:** ${loggedInEmail} \n**Logged in sub:** ${loggedInSub}`;
        }

        try {
            await fetch(discordWebhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(discordMessage)
            });

            handleClose();
        } catch (error) {
            console.error('Error sending message to Discord:', error);
        }
    };

    return (
        <>
            {props.children(handleOpen)}

            <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle className="tw-text-xl tw-font-bold tw-text-gray-800">Fabublox Feedback</DialogTitle>
                <DialogContent className="tw-bg-white tw-p-6">
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        className="tw-mb-4"
                    />
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={handleBlurEmail}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        error={emailError}
                        helperText={emailError ? "Please enter a valid email." : ""}
                        className="tw-mb-4"
                    />
                    <TextField
                        label={messagePlaceholder ?? "Your message..."}
                        value={message}
                        onChange={(e) => {
                            if (messageError && e.target.value.trim() !== '') setMessageError(false);
                            setMessage(e.target.value)
                        }}
                        onBlur={handleBlurMessage}
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        className="tw-mb-4"
                        error={messageError}
                        helperText={messageError ? "Message is required." : ""}
                    />
                </DialogContent>
                <DialogActions className="tw-bg-gray-50 tw-p-4">
                    <Button 
                        onClick={handleSubmit} 
                        variant="contained" 
                        color="primary" 
                        className="tw-bg-blue-600 tw-text-white tw-px-6 tw-py-2 tw-rounded"
                        disabled={messageError || emailError}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};