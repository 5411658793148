import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Column, Row } from '../Layout/layouts';
import { IconButton } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export const FabuSearch: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const iconButtonRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set('keyword', inputValue);
      history.push({ pathname: '/fabubase/', search: `?${searchParams.toString()}` });
      setIsMobileSearchVisible(false);
    }
  };

  useEffect(() => {
    if (isMobileSearchVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isMobileSearchVisible]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        iconButtonRef.current &&
        !iconButtonRef.current.contains(event.target as Node)
      ) {
        setIsMobileSearchVisible(false);
      }
    };
    if (isMobileSearchVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileSearchVisible]);

  return (
    <>
      {/* Mobile Search Overlay */}
      {isMobileSearchVisible && (
        <div className='tw-fixed tw-w-screen tw-h-screen tw-top-0 tw-left-0 md:tw-hidden'>
          <div className='tw-fixed tw-top-1/4 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-z-10'>
            <div className='tw-relative'>
              <span className='tw-absolute tw-inset-y-0 tw-left-2 tw-pl-2 tw-flex tw-items-center tw-z-10'>
                <SearchOutlinedIcon style={{ color: '#ffffff9A' }} />
              </span>
              <input
                ref={inputRef}
                type='text'
                className='tw-w-[90vw] tw-h-16 tw-bg-[#121212]/75 tw-backdrop-blur-md tw-p-4 tw-pl-12 tw-rounded-2xl tw-text-white tw-text-xl tw-outline-none'
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                onKeyDown={handleSearch}
                placeholder='Search the FabuBase'
              />
            </div>
          </div>
        </div>
      )}

      {/* Desktop Search Bar */}
      <Row className='tw-w-full'>
        <Column className='search-box tw-hidden md:tw-flex'>
          <input
            type='text'
            className='searchbox tw-text-white tw-bg-[#404040] tw-w-[50vw] tw-outline-2 tw-outline-offset-0 tw-outline-[#8E4CA8]/75'
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            onKeyDown={handleSearch}
            placeholder='Search the FabuBase...'
          />
        </Column>

        {/* Search Icon for Mobile */}
        <Column style={{ height: '50px' }} className='tw-flex md:tw-hidden'>
          <IconButton ref={iconButtonRef} sx={{ color: 'white', height: 50 }} onClick={() => setIsMobileSearchVisible(!isMobileSearchVisible)}>
            <SearchOutlinedIcon />
          </IconButton>
        </Column>
      </Row>
    </>
  );
};
