import { useLocation } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ProcessEditorPage } from './pages/ProcessEditorPage';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { MyProcesses } from './pages/MyProcesses';
import { SearchPage } from './pages/SearchPage';
import { LandingPage } from './pages/LandingPage';
import { PageHeader } from './components/PageHeader';
import { GroupProcesses } from './pages/GroupProcesses';
import { MyBlox } from './pages/MyBlox';
import { ProcessViewerPage } from './pages/ProcessViewerPage';
import { JoinGroupPage } from './components/JoinGroupPage';
import { ProcessSettingsProvider } from './hooks/state/process-settings-provider';
import { SVGTestPage } from './pages/SVGTestPage';
import { ModuleEditorPage } from './pages/ModuleEditorPage';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfServicePage';
import CareersPage from './pages/careers/CareersPage';

import { FoundingEngineerPage } from './pages/careers/FoundingEngineerPage';
import { MachineLearningEngineerPage } from './pages/careers/MlEngineerPage';
import { SemiconductorEngineerPage } from './pages/careers/SemiconductorEngineerPage';

export const FabuRoutes = () => {
  const location = useLocation(); 

  const showPageHeader = location.pathname !== '/' && !location.pathname.startsWith('/careers');

  return (
    <>
      {showPageHeader && <PageHeader />}
      <Switch>
        <Route exact={true} path={'/process-viewer/:processId?'}>
          <ProcessSettingsProvider key='viewer'>
            <ProcessViewerPage />
          </ProcessSettingsProvider>
        </Route>
        <Route exact={true} path='/'>
          <LandingPage />
        </Route>
        <Route exact={true} path='/privacy-policy'>
          <PrivacyPolicy />
        </Route>
        <Route exact={true} path='/terms-of-service'>
          <TermsOfService />
        </Route>
        <Route exact={true} path='/careers'>
          <CareersPage />
        </Route>
        <Route exact={true} path='/careers/founding-engineer'>
          <FoundingEngineerPage />
        </Route>
        <Route exact={true} path='/careers/machine-learning-engineer'>
          <MachineLearningEngineerPage />
        </Route>
        <Route exact={true} path='/careers/semiconductor-engineer'>
          <SemiconductorEngineerPage />
        </Route>
        <ProtectedRoute exact={true} path='/process-editor/:processId?'>
          <ProcessSettingsProvider key='process-editor'>
            <ProcessEditorPage />
          </ProcessSettingsProvider>
        </ProtectedRoute>
        <ProtectedRoute exact={true} path='/module-editor/:processId?'>
          <ProcessSettingsProvider isModule={true} key='module-editor'>
            <ModuleEditorPage />
          </ProcessSettingsProvider>
        </ProtectedRoute>
        <ProtectedRoute exact={true} path={'/my-blox'}>
          <MyBlox />
        </ProtectedRoute>
        <ProtectedRoute exact={true} path={'/my-processes'}>
          <MyProcesses key='my-processes' />
        </ProtectedRoute>
        <ProtectedRoute exact={true} path={'/my-group-processes'}>
          <GroupProcesses key='group-processes' />
        </ProtectedRoute>
        <ProtectedRoute exact={true} path={'/my-modules'}>
          <MyProcesses key='my-modules' isModule={true} />
        </ProtectedRoute>
        <ProtectedRoute exact={true} path={'/my-group-modules'}>
          <GroupProcesses key='group-modules' isModule={true} />
        </ProtectedRoute>
        <ProtectedRoute exact={true} path={'/fabubase'}>
          <SearchPage />
        </ProtectedRoute>
        <ProtectedRoute exact={true} path='/join-group'>
          <JoinGroupPage />
        </ProtectedRoute>
        <ProtectedRoute exact={true} path='/svg-test'>
          <SVGTestPage />
        </ProtectedRoute>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    </>
  );
};
