import React from 'react';
import { Container, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Divider, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';

export default function PrivacyPolicyContent() {
  const sections = [
    {
      title: "1. Identity and access",
      content: "When you sign up for a product, we ask for identifying information such as your name, email address, and affiliated organization names. That's so you can personalize your new account, and we can send you product updates and other essential information. We may also send you optional surveys from time to time to help us understand how you use our products and to make improvements. With your consent, we will send you our newsletter and other updates. We sometimes also give you the option to add a profile picture that displays in our products."
    },
    {
      title: "2. Product interactions",
      content: "We store on our servers the content that you upload or receive or maintain in your product accounts. This is so you can use our products as intended. We keep this content as long as your account is active. If you delete your account, we'll delete the content within 90 days."
    },
    {
      title: "3. General Geolocation data",
      content: "For most of our products, we log the full IP address used to sign up a product account and retain that for use in mitigating future spammy signups. We also log all account access by full IP address for security and fraud prevention purposes, and we keep this login data for as long as your product account is active."
    },
    {
      title: "4. Website interactions",
      content: "We collect information about your browsing activity on our Services for analytics and statistical purposes such as conversion rate testing and experimenting with new product designs. This includes, for example, your browser and operating system versions, your IP address, which web pages you visited on our site and how long they took to load, and which website referred you to us. If you have an account and are signed in, these web analytics data are tied to your IP address and user account until your account is no longer active."
    },
    {
      title: "5. Cookies",
      content: "By accessing our websites or services, you agree to the use of cookies. Most interactive websites use cookies to improve the user experience by remembering site preferences, maintain temporary data, such as unsaved changes, and/or deliver cached data for faster service responsiveness. You can adjust cookie retention settings and accept or block individual cookies in your browser settings, although certain aspects of our service may not function properly if you turn cookies off."
    }
  ];

  const rights = [
    "Right to Know",
    "Right of Access",
    "Right to Correction",
    "Right to Erasure / \"To Be Forgotten\"",
    "Right to Complain",
    "Right to Restrict Processing",
    "Right to Object",
    "Right to Portability",
    "Right to not Be Subject to Automated Decision-Making",
    "Right to Non-Discrimination"
  ];

  const copyToClipboard = (hash: string) => {
    const url = `${window.location.origin}/privacy-policy${hash}`;
    navigator.clipboard.writeText(url);
  };

  return (
    <Container maxWidth="md" className="tw-py-8">
      <Paper elevation={3} className="tw-p-8 tw-space-y-6 tw-rounded-xl">
        <Typography variant="h3" component="h1" className="tw-text-4xl tw-font-bold tw-mb-4">
          FabuBlox Privacy Policy
        </Typography>
        <Typography variant="subtitle1" className="tw-text-gray-600">
          Last Updated: August 25th, 2024
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          In this policy, we lay out: what data we collect and why; how your data is handled; and your rights with respect to your data. We never sell your data. You have opted-in to this Privacy Policy which meets GDPR regulations.
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          This policy applies to all products built and maintained by Fabublox, Inc.
        </Typography>
        <Typography variant="body1" className="tw-mb-6">
          We may update this policy at any time. Account owners will be notified of any significant changes via email.
        </Typography>

        <div className="tw-flex tw-items-center tw-gap-2" id="collection">
          <Typography variant="h5" component="h2" className="tw-font-bold tw-mb-4">
            What we collect and why
          </Typography>
          <IconButton 
            size="small" 
            onClick={() => copyToClipboard('#collection')}
            className="tw-text-gray-400 hover:tw-text-gray-600 tw-mb-3"
          >
            <LinkIcon fontSize="medium" />
          </IconButton>
        </div>
        {sections.map((section, index) => (
          <Accordion key={index} className='tw-rounded-xl'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{section.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{section.content}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        <div className="tw-flex tw-items-center tw-gap-2" id="security-encryption">
          <Typography variant="h5" component="h2" className="tw-font-bold tw-mt-8 tw-mb-4">
            Security and Encryption
          </Typography>
          <IconButton 
            size="small" 
            onClick={() => copyToClipboard('#security-encryption')}
            className="tw-text-gray-400 hover:tw-text-gray-600 tw-mt-5"
          >
            <LinkIcon fontSize="medium" />
          </IconButton>
        </div>
        <Typography variant="body1" className="tw-mb-4">
          Use of our Services is at your sole risk. We provide these Services on an "as is" and "as available" basis.
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          All data is encrypted via SSL/TLS when transmitted from our servers to your browser. The database backups are also encrypted.
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          Data is encrypted at-rest. This is drive level encryption. You may read MongoDB Atlas and Google Cloud security policies for more information.
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          Data is not encrypted within the database itself (since they need to be ready to send to you when you need them) but all data is encrypted via SSL/TLS when in transit from or to the database.
        </Typography>

        <div className="tw-flex tw-items-center tw-gap-2" id="your-rights">
          <Typography variant="h5" component="h2" className="tw-font-bold tw-mt-8 tw-mb-4">
            Your rights with respect to your information
          </Typography>
          <IconButton 
            size="small" 
            onClick={() => copyToClipboard('#your-rights')}
            className="tw-text-gray-400 hover:tw-text-gray-600 tw-mt-5"
          >
            <LinkIcon fontSize="medium" />
          </IconButton>
        </div>
        <Typography variant="body1" className="tw-mb-4">
          We strive to apply the same data rights to all customers, regardless of their location. GDPR regulations requires these rights to be easy to comprehend. Some of these rights include:
        </Typography>
        <div className='tw-bg-slate-100 tw-rounded-xl'>
            <List>
            {rights.map((right, index) => (
                <ListItem key={index}>
                <ListItemText primary={right} />
                </ListItem>
            ))}
            </List>
        </div>
        <Typography variant="body1" className="tw-mt-4">
          Many of these rights can be exercised by signing in and updating your account information. Please note that certain information may be exempt from such requests under applicable law. For example, we need to retain certain information in order to provide our services to you.
        </Typography>
        <Typography variant="body1" className="tw-mt-4">
          In some cases, we also need to take reasonable steps to verify your identity before responding to a request, which may include, at a minimum, depending on the sensitivity of the information you are requesting and the type of request you are making, verifying your name and email address. If we are unable to verify you, we may be unable to respond to your requests. If you have questions about exercising these rights or need assistance, please contact us at hello@fabublox.com
        </Typography>

        <div className="tw-flex tw-items-center tw-gap-2" id="account">
          <Typography variant="h5" component="h2" className="tw-font-bold tw-mt-8 tw-mb-4">
            Account Deletion and Data Retention
          </Typography>
          <IconButton 
            size="small" 
            onClick={() => copyToClipboard('#account')}
            className="tw-text-gray-400 hover:tw-text-gray-600 tw-mt-5"
          >
            <LinkIcon fontSize="medium" />
          </IconButton>
        </div>
        <Typography variant="body1" className="tw-mb-4">
          In many of our applications, we give you the option to delete content. The deleted content cannot be accessed via the application and we are not able to retrieve it for you. The trashed content may remain on our active servers for another 30 days, and copies of the content may be held in backups of our application databases for up to another 60 days after that. Altogether, any content trashed in your product accounts should be purged from all of our systems and logs within 90 days.
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          If you choose to cancel your account, your content will become immediately inaccessible and should be purged from our systems in full within 90 days. This applies both for cases when an account owner directly cancels and for auto-canceled accounts.
        </Typography>

        <div className="tw-flex tw-items-center tw-gap-2" id="data">
          <Typography variant="h5" component="h2" className="tw-font-bold tw-mt-8 tw-mb-4">
            Location of site and data
          </Typography>
          <IconButton 
            size="small" 
            onClick={() => copyToClipboard('#data')}
            className="tw-text-gray-400 hover:tw-text-gray-600 tw-mt-5"
          >
            <LinkIcon fontSize="medium" />
          </IconButton>
        </div>
        <Typography variant="body1" className="tw-mb-4">
          Our products and other web properties are primarily housed in the United States. If you are located in the European Union, UK, or elsewhere outside of the United States, please be aware that any information you provide to us will be transferred to and stored in the United States. By using our websites or Services and/or providing us with your personal information, you consent to this transfer.
        </Typography>

        <div className="tw-flex tw-items-center tw-gap-2" id="your-information">
          <Typography variant="h5" component="h2" className="tw-font-bold tw-mt-8 tw-mb-4">
            When we access or disclose your information
          </Typography>
          <IconButton 
            size="small" 
            onClick={() => copyToClipboard('#your-information')}
            className="tw-text-gray-400 hover:tw-text-gray-600 tw-mt-5"
          >
            <LinkIcon fontSize="medium" />
          </IconButton>
        </div>
        <Typography variant="body1" className="tw-mb-4">
          To provide products or services you've requested. We use some third-party subprocessors to help run our applications and provide the Services to you. All are GDPR compliant:
        </Typography>
        <div className='tw-bg-slate-100 tw-rounded-xl'>
            <List>
            <ListItem>
                <ListItemText primary="MongoDB Atlas: https://www.mongodb.com/legal/data-processing-agreement" />
            </ListItem>
            <ListItem>
                <ListItemText primary="Google Cloud Provider: https://cloud.google.com/terms/data-processing-addendum" />
            </ListItem>
            <ListItem>
                <ListItemText primary="Auth0: https://auth0.com/docs/secure/data-privacy-and-compliance/gdpr" />
            </ListItem>
            </List>
            
        </div>
        <Typography variant="body1" className="tw-mt-4">
          Each of these subprocessors have their own privacy policies that meet the protections in this privacy policy.
        </Typography>

        <Divider className="tw-my-8" />

        <Typography variant="h5" component="h2" className="tw-font-bold tw-mb-4">
          Contact and Questions
        </Typography>
        <Typography variant="body1">hello@fabublox.com</Typography>
        <Typography variant="body1" className="tw-mt-2">
          Data Processing Officer (DPO):
        </Typography>
        <Typography variant="body1">Jack Muller - jack@fabublox.com</Typography>

        <Typography variant="body2" className="tw-mt-8 tw-text-gray-600">
          Attribution under CC BY 4.0: This Privacy Policy was adapted from the Basecamp open-source policies / CC BY 4.0
        </Typography>
      </Paper>
    </Container>
  );
}