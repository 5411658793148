import { Divider } from "@blueprintjs/core";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

export const WhyJoinFabuBlox = () => (
    <>
     <Typography variant="h6" className="tw-font-semibold tw-mt-8">
                Why Join FabuBlox?
              </Typography>
              <List>
      <ListItem>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body1',
          }}
        >
          Help bring <strong>next-generation tools</strong> to the semiconductor and nanotechnology industries, redefining how innovation happens.
        </ListItemText>
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body1',
          }}
        >
          Work on a <strong>mission-critical product</strong> that will shape the future of high-tech R&D and manufacturing.
        </ListItemText>
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body1',
          }}
        >
          Collaborate closely with a <strong>small, passionate team</strong>, and make a direct impact from day one.
        </ListItemText>
      </ListItem>
    </List>
    </>
    
  );