import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import { AboutFabuBlox } from "../../components/Careers/AboutFabublox";
import { Container, Button } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import GridBackground from "../../components/GridBackground";

const jobPostings = [
  {
    id: "founding-engineer",
    title: "Founding Software Engineer",
    department: "Engineering",
    location: "Boston Office or Hybrid",
    type: "Full time",
  },
  {
    id: "machine-learning-engineer",
    title: "Machine Learning / AI Engineer",
    department: "Engineering",
    location: "Boston Office or Hybrid",
    type: "Full time",
  },
  {
    id: "semiconductor-engineer",
    title: "Semiconductor Fabrication Engineer • Product and Strategy",
    department: "Product",
    location: "Boston Office or Hybrid",
    type: "Full or Part time",
  },
];

export default function CareersPage() {
  const history = useHistory();

  const handleItemClick = (id: string) => {
    history.push(`/careers/${id}`);
  };

  return (
    <div className="tw-relative tw-w-full tw-min-h-screen tw-overflow-auto">
      <GridBackground />
      <Container maxWidth="md">
        <Paper elevation={0} className="tw-rounded-md tw-my-4 tw-bg-transparent">
          <div className="tw-flex tw-justify-center tw-px-4 tw-pb-16">
            <div className="tw-w-full tw-max-w-[700px]">
              <div className="tw-flex tw-flex-col tw-space-y-4 ">
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => history.push("/")}
                  style={{ width: "80px" }}
                  className="tw-text-gray-700 hover:tw-bg-gray-100 
                  tw-rounded-md tw-transition-colors tw-duration-200 tw-ease-in-out"
                >
                  Home
                </Button>
                <AboutFabuBlox />
                <Paper elevation={1} className="tw-p-10 tw-rounded-md">
                  <Typography variant="h5" className="tw-font-medium tw-text-lg tw-mb-4">
                    Open Positions ({jobPostings.length})
                  </Typography>
                  {jobPostings.map((job) => (
                    <div key={job.id}>
                      <div
                        className="
                          tw-border tw-border-gray-300 tw-rounded-xl tw-p-0.5 tw-cursor-pointer 
                          tw-bg-white tw-transition-all tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-to-[#ff9567] tw-mb-4"
                      >
                        <div
                          onClick={() => handleItemClick(job.id)}
                          className="tw-rounded-lg tw-px-4 tw-py-3 tw-cursor-pointer tw-bg-white tw-transition-all 
                          tw-duration-200 hover:tw-opacity-80 hover:tw-shadow-md"
                        >
                          <Typography variant="subtitle1" className="tw-font-semibold">
                            {job.title}
                          </Typography>
                          <Typography variant="body2" className="tw-text-gray-600 tw-mt-1">
                            {job.department} • {job.location} • {job.type}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ))}
                </Paper>
              </div>
            </div>
          </div>
        </Paper>
      </Container>
    </div>
  );
}
