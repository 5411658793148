import { Typography } from "@mui/material"
import JobPosting, { KeyInformationItem } from "../../components/Careers/JobPosting"
import { InfoList } from "../../components/Careers/InfoList";
import { WhyJoinFabuBlox } from "../../components/Careers/WhyJoinFabuBlox";

export function FoundingEngineerPage() {
    const keyInformationItems: KeyInformationItem[] = [
        {
            label: "Location",
            value: "Boston Office or Hybrid",
        },
        {
            label: "Title",
            value: "Founding Software Engineer",
        },
        {
            label: "Experience",
            value: "4+ years",
        },
    ];

    const aboutTheRole = <><Typography variant="h6" className="tw-font-semibold">
        About the Role
    </Typography>
        <Typography className="tw-my-4">
            As a <strong>Founding Software Engineer</strong>, you’ll play a
            critical role in shaping FabuBlox’s core product and technology
            stack. From building user-facing features to architecting scalable
            backend systems, you’ll help define our technical direction and
            ensure our platform meets the needs of both individual users and
            enterprise clients.
        </Typography>
        <Typography className="tw-my-4">
            This role isn’t just about coding—it’s about ownership, innovation,
            and impact. You’ll work directly with the founders, shape the
            roadmap, and contribute to building a platform that stands up to
            the demands of enterprise-level security, reliability, and
            scalability.
        </Typography>
        <Typography className="tw-my-4">
            FabuBlox is commited to creating a friendly, inclusive and diverse workplace. We encourage applications from underrepsented groups in tech!
        </Typography>
        
        </>;

    const jobSpecs = <>
        <InfoList
            title="Technical Experience"
            items={[
                "4+ years of engineering experience, including building and shipping real-world applications with high-quality standards.",
                <>Proficiency in rapidly translating ideas into products using <strong>React</strong>, <strong>TypeScript</strong>, and <strong>TailwindCSS</strong>.</>,
                "Expertise in designing and deploying customer-facing APIs and microservices used by enterprise level customers.",
                <>Strong understanding of cloud ecosystems, particularly <strong>GCP services</strong> such as <strong>Cloud Run</strong>, <strong>Cloud Functions</strong>, and <strong>Cloud Storage</strong>. Including robust CI/CD pipelines for efficient, reliable releases.</>
            ]}
        />

        <InfoList
            title="You Will"
            items={[
                "Collaborate closely with founders to shape the product roadmap, taking ownership of key features and driving meaningful improvements.",
                "Be comfortable working in fast-paced startup environments and thriving in ambiguity.",
                "Engage in enterprise sales processes and the associated security, compliance, and reliability requirements.",
                "Focus on creating tools that simplify complex workflows and empower users.",
                "Prioritize logging, monitoring, and automated testing to ensure system reliability, maintainability, and observability."
            ]}
        />

        <InfoList
            title="Bonus Points"
            items={[
                <>Prior experience as a <strong>founder</strong> or <strong>founding engineer</strong>.</>,
                <>Knowledge of the <strong>semiconductor</strong>, <strong>nanotechnology</strong>, or adjacent cutting-edge industries.</>
            ]}
        />
    </>;

    const jobPostingProps = {
        keyInformationItems,
        jobTitle: "Founding Software Engineer"
    };

    return (
        <JobPosting {...jobPostingProps}>
            {aboutTheRole}
            {jobSpecs}
            <WhyJoinFabuBlox />
        </JobPosting>
    );
}