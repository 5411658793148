import * as React from 'react';
import { Container } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { fabutheme } from '../MaterialUI/muicolortheme';
import { ContactDialog } from '../dialogs/ContactDialog';

import { Column } from '../Layout/layouts';

export const Footer: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={fabutheme}>
        <div className='tw-bg-[#404040] tw-pb-4'>
          <Container maxWidth="xl"> 
            <Column>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-start tw-px-4 md:tw-px-12 tw-pt-8">
              <div className="tw-text-white tw-items-start tw-mb-4 md:tw-mb-0 md:tw-pr-4">
                <h4>Send us a Message</h4>
                <ContactDialog>
                  {(handleOpen: () => void) => (
                    <a onClick={handleOpen} className='tw-text-white hover:tw-text-transparent tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-via-[#d35299] tw-to-[#ff9567] tw-bg-clip-text'>hello@fabublox.com</a>
                  )}
                </ContactDialog>
              </div>
              <div className='tw-flex tw-flex-col tw-items-start tw-text-white'>
                <div className='tw-mb-2'>Made with <span className='tw-text-transparent tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-via-[#d35299] tw-to-[#ff9567] tw-bg-clip-text tw-font-serif tw-font-extrabold tw-italic tw-text-lg tw-text-center '>fabulove</span> in Boston.</div>
                <div>FabuBlox Inc. All rights reserved.</div>
              </div>
              <div className="tw-text-white tw-flex tw-flex-col sm:tw-flex-row tw-col-span-1 tw-mb-4 md:tw-mb-0">
                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" 
                className="
                  tw-mb-2 sm:tw-mb-0 
                  tw-text-white
                  sm:tw-mr-4 hover:tw-text-transparent 
                  tw-bg-gradient-to-r tw-from-[#8E4CA8] 
                  tw-via-[#d35299] tw-to-[#ff9567] 
                  tw-bg-clip-text focus:tw-outline-none">
                  Privacy Policy
                </a>
                <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" 
                className='hover:tw-text-transparent  tw-text-white
                  tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-via-[#d35299]
                   tw-to-[#ff9567] tw-bg-clip-text focus:tw-outline-none'>
                  Terms of Service
                  </a>
              </div>
              
            </div>
            </Column>
          </Container>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
