import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'

import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { useCallback, useRef, useEffect, useState, useMemo } from 'react'
import { ColDef, ModuleRegistry } from '@ag-grid-community/core'
import { Link } from 'react-router-dom'
import { SearchHeader } from '../components/SearchHeader'
import { Column } from '../Layout/layouts'
import { ProcessPreviewRenderer } from '../list/ProcessPreviewRenderer'
import { Icon, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { DeleteRenderer } from '../components/CellRenderers/DeleteRenderer'
import { useOwnedProcesses } from '../hooks/DataFetching/use-fetch-process'
import { Loading } from '../components/Loading'
import { searchProcessAndBlox } from '../utils/search-process-and-blox'
import { BloxesAndThumbnail } from './SearchPage'

interface ProcessListResult {
  processName: string
  processId: string
  bloxesInfo: BloxesAndThumbnail
  createdAt: string
  lastUpdatedAt: string,
  additionalOwners: string[],
  isPrivate: boolean,
  groups: {id: string, groupName: string}[]
}

export const MyProcesses: React.FC<{isModule?: boolean}> = ({isModule}) => {
  const resourceName = isModule ? 'module' : 'process';
  const searchPlaceholder = `Filter ${isModule ? 'modules' : 'processes'}...`;
  const upperResourceName = resourceName.charAt(0).toUpperCase() + resourceName.slice(1);
  const [filterBy, setFilterBy] = useState<string>();
  const { data, isLoading, error } = useOwnedProcesses(isModule);
  const rowData: ProcessListResult[] | undefined = useMemo(() => {
    if (data) {
      return data.map((process: any) => {
        return {
          processName: process.processName,
          processId: process.processId,
          bloxesInfo: { bloxes: process.bloxes, thumbnail: process.thumbnail },
          createdAt: process.createdAt,
          lastUpdatedAt: process.lastUpdatedAt,
          additionalOwners: process.additionalOwners,
          isPrivate: process.isPrivate,
          groups: process.groups
        }
      });
    }
  }, [data]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const keywordParam = queryParams.get('keyword')
    if (keywordParam) {
      setFilterBy(keywordParam);
    }
  }, []);

  ModuleRegistry.registerModules([ClientSideRowModelModule]);

  const additionalOwnersTooltip = `This ${resourceName} has additional owners who can edit it.`;
  const additionalOwnersEl = <Tooltip content={additionalOwnersTooltip}><Icon style={{margin: 'auto'}} icon={IconNames.PEOPLE}></Icon></Tooltip>;

  const publicTooltip = "This process is publicly visible in Fabubase.";
  const privateTooltip = "This process is private.";

  const [columnDefs] = useState<ColDef[]>([

    {
      headerName: '',
      field: 'bloxesInfo',
      minWidth: 90,
      width: 90,
      maxWidth: 90,
      cellRenderer: ProcessPreviewRenderer,
      resizable: true,
      sortable: false,
      filter: false,
    },
    {
      headerName: '',
      minWidth: 35,
      width: 35,
      field: 'additionalOwners',
      cellRenderer: ({ value }: { value: string[] | undefined }) => value?.length ? additionalOwnersEl : <></>,
      cellStyle: {
        display: 'flex',
        height: '100%',
        whiteSpace: 'normal',
        overflow: 'visible'
      }
    },
    {
      headerName: `${upperResourceName} Name`,
      field: 'processName',
      width: 500,
      cellRenderer: (props: any) => <Link to={`/${resourceName}-editor/${props?.node?.data?.processId}`}>{props.value}</Link>
    },
    {
      headerName: 'Visibility',
      minWidth: 60,
      width: 110,
      field: 'isPrivate',
      cellRenderer: ({ value }: { value: boolean }) => <Tooltip content={value ? privateTooltip : publicTooltip}><Icon icon={value ? IconNames.LOCK : IconNames.GLOBE}></Icon></Tooltip>,
      hide: isModule
    },
    {
      headerName: 'Groups',
      field: 'groups',
      width: 300,
      valueGetter: (props) => {
        if (!props?.node?.data?.groups?.length) {
          return '';
        }
        const groupNames = props.node.data.groups.map((g: { id: string, groupName: string }) => g.groupName);
        return groupNames.join(', ');
      }
    },
    {
      headerName: 'Creator',
      field: 'username'
  },
    {
      headerName: 'No. of Steps',
      minWidth: 70,
      width: 135,
      valueGetter: (props) => {
        const bloxes = props?.node?.data?.bloxesInfo?.bloxes;
        return bloxes ? bloxes.length : 0;
      },
      type: 'numericColumn'
    },
    {
      headerName: 'Last Updated',
      field: 'lastUpdatedAt',
      width: 200,
      valueFormatter: ({ value }) => value.substring(0, 10),
      sort: 'desc',
    },
    {
      headerName: 'Created On',
      field: 'createdAt',
      width: 200,
      valueFormatter: ({ value }) => value.substring(0, 10)
    },
    {
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      cellRenderer: DeleteRenderer,
      cellRendererParams: {
        deleteUri: `${resourceName}/delete`,
        idField: 'processId',
        nameField: 'processName',
      }
    }
  ]);

  const gridRef = useRef(null);

  const filterFunc = useCallback((row: ProcessListResult) => {
    return searchProcessAndBlox(row, filterBy);
  }, [filterBy]);

  if (isLoading) return <Loading />;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
		<Column className={'searchContainer'}>
		<SearchHeader
      placeholder={searchPlaceholder}
			filterBy={filterBy}
			handleChangeFilter={(val: string) => {
				

			setFilterBy(val)
			}}
		/>
		<AgGridReact
			className='ag-theme-alpine'
			ref={gridRef}
			rowData={(rowData ?? []).filter(filterFunc)}
			columnDefs={columnDefs}
			rowHeight={50}
			defaultColDef={{
			editable: false,
			sortable: true,
			minWidth: 200,
			filter: true,
			resizable: true,
			}}
		/>
		</Column>
  );
}
