  import { useHistory } from 'react-router-dom'
  import { Row } from '../Layout/layouts'
  import Box from '@mui/material/Box';
  import Drawer from '@mui/material/Drawer';
  import IconButton from '@mui/material/IconButton';
  import List from '@mui/material/List';
  import Divider from '@mui/material/Divider';
  import ListItem from '@mui/material/ListItem';
  import ListItemButton from '@mui/material/ListItemButton';
  import ListItemText from '@mui/material/ListItemText';
  import LoginLogoutButton from './login-logout-button';
  import MenuIcon from '@mui/icons-material/Menu';
  import React from 'react'
  
  
  export const HamburgerMenu: React.FC = () => {
    const history = useHistory()
    const [open, setOpen] = React.useState(false);
  
    const toggleDrawer = (newOpen: boolean) => () => {
      setOpen(newOpen);
    };
  
    const DrawerList = (
      <div className='tw-flex tw-flex-col tw-min-h-[100vh] '>
      <Box sx={{ width: 200 }} role="presentation" onClick={toggleDrawer(false)}>
        <List>
        {[{ text: 'Home', path: '/' }].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => history.push(item.path)}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        </List>
        <Divider />
        <List>
        {[
          { text: 'Process Editor', path: '/process-editor' },
          { text: 'My Processes', path: '/my-processes' },
          { text: 'Group Processes', path: '/my-group-processes' }
        ].map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => history.push(item.path)}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        </List>
        <Divider />
        <List>
        {[
          { text: 'Module Editor', path: '/module-editor' },
          { text: 'My Modules', path: '/my-modules' },
          { text: 'Group Modules', path: '/my-group-modules' }
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => history.push(item.path)}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        </List>
        <Divider />
        <List>
          {[
            { text: 'Fabubase', path: '/fabubase' }
          ].map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton onClick={() => history.push(item.path)}>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      </div>
    
    );
  
    return (
      <Row style={{ margin: 'auto' }}>
      <div>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon style={{ color: '#ffffff' }} />
        </IconButton>
        <Drawer
          open={open}
          onClose={toggleDrawer(false)}>
          <Box 
            display="flex"
            flexDirection="column"
            height="100%"
          >
            {DrawerList}
            <Box 
            mt="auto" pl={1} pb={2}>
              <LoginLogoutButton 
              />
            </Box>
          </Box>
        </Drawer>
      </div>
    </Row>
    )
  }
  
  