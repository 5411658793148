import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

/**
 * This redirectUri must match the callback url in auth0 exactly. Do not change lightly.
 */
const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
  
    return (
      <Button
        onClick={() =>
          loginWithRedirect({
            redirectUri: `${window.location.origin}/fabubase`,
          })
        }
        className="tw-text-white"
        disableRipple={true}
      >
        Log In
      </Button>
    );
  };
  
  export default LoginButton;
  

