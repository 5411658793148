import React from 'react';
import {
  Tag,
  Tooltip,
  Colors,
} from '@blueprintjs/core';
import { ReactComponent as LogoIcon } from '../components/svg/STARTSTACK.svg';
import { ContactDialog } from '../dialogs/ContactDialog';
import { Row } from '../Layout/layouts';
import LoginLogoutButton from './login-logout-button';
import { HamburgerMenu } from './HamburgerMenu';
import { useAuth0 } from "@auth0/auth0-react";
import { ButtonGroup, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

const LandingNavBar: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();

  const pixNo = 25;
  const iconStyle = {
    width: `${pixNo}px`,
    height: `${pixNo}px`,
  };

  const betaStyle = {
    backgroundColor: Colors.LIGHT_GRAY2,
    color: 'black',
    fontSize: '16px',
    height: '40px',
    marginLeft: '5px',
  };

  return (
    <div className='tw-h-16 tw-sticky tw-top-0 tw-z-10'>
      <header
        id="stickyHeader"
        className={`tw-sticky tw-top-0 tw-flex tw-items-center tw-justify-between 
                  tw-bg-[#121212]/85  tw-backdrop-blur-md tw-py-4 tw-px-4 
                tw-w-full tw-max-h-16`}
      >
        <Row>
          <ButtonGroup>
            {isAuthenticated && <HamburgerMenu />}
            <Tooltip
              content={
                <>
                  <p>
                    Fabublox is in beta testing. We appreciate your feedback as we add more features.
                    <br />
                    Please use the contact button to share your thoughts with us.
                  </p>
                  <p>Thank you!</p>
                </>
              }
            >
              <Tag icon={<LogoIcon style={iconStyle} />} style={betaStyle} large={true}>
                Beta
              </Tag>
            </Tooltip>
          </ButtonGroup>
        </Row>
        <Row className="tw-hidden sm:tw-flex">
          <div className="
        tw-flex lg:tw-gap-32 md:tw-gap-16 sm:tw-gap-8 
        tw-justify-between tw-w-full sm:tw-w-full md:tw-w-full lg:tw-w-full"
          >
            <Button
              variant="text"
              disableRipple={true}
              disableElevation={true}
              className="
          tw-text-white tw-font-normal tw-flex-grow tw-border-0 
          tw-bg-transparent hover:tw-bg-transparent focus:tw-outline-none"
              onClick={() => document.getElementById('FabuBaseSection')?.scrollIntoView({ behavior: 'smooth' })}
            >
              FabuBase
            </Button>
            <Button
              variant="text"
              disableRipple={true}
              disableElevation={true}
              className="
          tw-text-white tw-font-normal tw-flex-grow 
          tw-border-0 tw-bg-transparent hover:tw-bg-transparent focus:tw-outline-none"
              onClick={() => document.getElementById('ProcessEditor')?.scrollIntoView({ behavior: 'smooth' })}
            >
              Process Editor
            </Button>
            <ContactDialog>
              {(handleOpen: () => void) => (
                <Button
                  variant="text"
                  disableRipple={true}
                  disableElevation={true}
                  onClick={handleOpen}
                  className="
                    tw-text-white tw-font-normal tw-flex-grow tw-border-0 
                    tw-bg-transparent hover:tw-bg-transparent focus:tw-outline-none"
                >
                  Contact
                </Button>
              )}
            </ContactDialog>
            <Button
              variant="text"
              disableRipple={true}
              disableElevation={true}
              className="
          tw-text-white tw-font-normal tw-flex-grow 
          tw-border-0 tw-bg-transparent hover:tw-bg-transparent focus:tw-outline-none"
              onClick={() => history.push('/careers')}
            >
              Careers
            </Button>
          </div>
        </Row>
        <LoginLogoutButton />
      </header>
    </div>
  );
};
export default LandingNavBar;