import { Classes, H4, H6 } from "@blueprintjs/core"
import { Column } from "../Layout/layouts"
import { Container } from "@mui/material"

export const TeamListing: React.FC = () => {
  return (
    <div className="">
    <Container maxWidth="xl">
    <div className="tw-flex tw-justify-center tw-px-8 tw-z-0">
    <div className=" tw-rounded-xl tw-px-12">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-4 ">
        <div className="tw-mb-8">
    <div className={'team-listing'}>
      <h2 className=" tw-text-6xl tw-font-black tw-text-left">Our Team</h2>
      <div className='tw-bg-black tw-w-1/4 tw-justify-start tw-h-0.5 tw-mx-1 tw-mb-4'></div>
      <div className='team-wrap tw-gap-8'>


      <a href="https://www.linkedin.com/in/jan-tiepelt-683a621b6/" target="_blank" rel="noopener noreferrer" className="tw-no-underline focus:tw-outline-none">
      <div className='team-box tw-mb-8  tw-bg-white tw-rounded-xl tw-p-2 tw-py-4 tw-outline tw-outline-[#d1d5ee] tw-outline-4 hover:-tw-translate-y-2 hover:tw-shadow-2xl'>
        <img className="tw-rounded-full tw-outline tw-outline-white tw-outline-8 tw-shadow-sm tw-z-0" style={{ width: '30%' }} src='jan_headshot.png' alt='Logo' />
          <Column className='description '>
            <H4 style={{marginTop: '80px'}}>Dr. Jan Tiepelt</H4>
            <H6>CEO </H6>
            <Column className={'profile-description'}>
              <p className={Classes.UI_TEXT}>
              PhD, EECS, MIT <br/>
              MSc Materials Science & Engineering,<br/>
              ETH Zurich<br/></p>
              <p className={Classes.TEXT_MUTED}>
              Nano- and microfabrication expert <br/>
              8+ years of cleanroom experience
              </p>
            </Column>
          </Column>
        </div>
        </a>
        <a href="https://www.linkedin.com/in/jack-muller-b56450113/" target="_blank" rel="noopener noreferrer" className="tw-no-underline focus:tw-outline-none">
        <div className='team-box tw-mb-8 tw-bg-white tw-rounded-xl tw-p-2 tw-py-4 tw-outline tw-outline-[#d1d5ee] tw-outline-4 hover:-tw-translate-y-2 hover:tw-shadow-2xl'>
          <img className="tw-rounded-full tw-outline tw-outline-white tw-outline-8 tw-shadow-sm tw-z-0" style={{ width: '30%' }} src='jack_headshot.png' alt='Logo' />
          <Column className='description '>
          <H4 style={{marginTop: '80px'}}>Jack Muller</H4>
            <H6>CTO | Lead Software Engineer</H6>
            <Column className={'profile-description'}>
              <p className={Classes.UI_TEXT}>
              Former Engineering Manager<br/>
              BA Computer Science,<br/> Amherst College<br/></p>
              <p className={Classes.TEXT_MUTED}>
              Professional full-stack developer leading agile teams to market-ready solutions
              </p>
            </Column>
          </Column>
        </div>
        </a>
        <a href="https://www.linkedin.com/in/eyal-perry-593b722a/" target="_blank" rel="noopener noreferrer" className="tw-no-underline focus:tw-outline-none">
        <div className='team-box tw-mb-8  tw-bg-white tw-rounded-xl tw-p-2 tw-py-4 tw-outline tw-outline-[#d1d5ee] tw-outline-4 hover:-tw-translate-y-2 hover:tw-shadow-2xl'>
          <img className="tw-rounded-full tw-outline tw-outline-white tw-outline-8 tw-shadow-sm tw-z-0" style={{ width: '30%' }} src='eyal_headshot.png' alt='Logo' />
          <Column className='description '>
            <H4 style={{marginTop: '80px'}}>Eyal Perry</H4>
            <H6>Algorithms Engineering Lead</H6>
            <Column className={'profile-description'}>
              <p className={Classes.UI_TEXT}>
              PhD Student, Media Lab, MIT <br/>
              BSc Computer Science & Biology,<br/>
              Tel Aviv University<br/></p>
              <p className={Classes.TEXT_MUTED}>
              Data science & visualizations<br/>
              10+ years of programming experience
              </p>
            </Column>
          </Column>
        </div>
        </a>
        <a href="https://www.linkedin.com/in/josh-perozek-05a02ba5/" target="_blank" rel="noopener noreferrer" className="tw-no-underline focus:tw-outline-none">
        <div className='team-box tw-mb-8 tw-bg-white tw-rounded-xl tw-p-2 tw-py-4 tw-outline tw-outline-[#d1d5ee] tw-outline-4 hover:-tw-translate-y-2 hover:tw-shadow-2xl'>
          <img className="tw-rounded-full tw-outline tw-outline-white tw-outline-8 tw-shadow-sm tw-z-0" style={{ width: '30%' }} src='josh_headshot.png' alt='Logo' />
          <Column className='description '>
            <H4 style={{marginTop: '80px'}}>Dr. Josh Perozek</H4>
            <H6>Process & Simulations Lead</H6>
            <Column className={'profile-description'}>
              <p className={Classes.UI_TEXT}>
              PhD, EECS, MIT <br/>
              BSc Electrical Engineering,<br/>
              U of I at Urbana-Champaign<br/></p>
              <p className={Classes.TEXT_MUTED}>
              Semiconductor & transistor fab expert <br/>
              3+ years MIT Process Tech Committee
              </p>
            </Column>
          </Column>
        </div>
        </a>
      </div>
    </div>
        </div>
      </div>
    </div>
    </div>
    </Container>

    </div>
  )
}
