import { PageNamesSlug } from "../Data/enums";

export interface PageInfo {
    pageName: string
}

export const BASE_FABUBLOX_API_URL = process.env["REACT_APP_REST_API"];
export const SEMIFAB_API_URL = process.env["REACT_APP_SEMIFAB_API"];


export const PAGE_INFO: Readonly<Record<PageNamesSlug, PageInfo>> = {
    [PageNamesSlug.ProcessEditor]: { pageName: 'Process Editor' },
    [PageNamesSlug.ModuleEditor]: { pageName: 'Module Editor' },
    [PageNamesSlug.Fabubase]: { pageName: 'Fabubase' },
    [PageNamesSlug.MyProcesses]: { pageName: 'My Processes' },
    [PageNamesSlug.MyGroupProcesses]: { pageName: 'Group Processes' },
    [PageNamesSlug.MyModules]: { pageName: 'My Modules' },
    [PageNamesSlug.MyGroupModules]: { pageName: 'Group Modules' }
};