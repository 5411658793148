import {Dialog, Button, Intent, Classes, ButtonProps, Tooltip} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useCallback, useContext, useState } from 'react';
import { Input } from '../components/Fields/Input';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { Row } from '../Layout/layouts';
import { openDialogAndWaitForInput } from './SaveYourChangesDialog';
import { UnsavedChangesContext } from '../hooks/state/unsaved-changes-provider';
import { validate } from 'uuid';

export const DuplicateDialogButton: React.FC<{onDuplicate: (newProcessName: string) => void, buttonProps: ButtonProps, isModule?: boolean}> = (props) => {
    const [processName] = useFabuState('processName');
    const [processIsReadOnly] = useFabuState('processIsReadOnly');
    const [processId] = useFabuState('processId');
    const hasValidId = validate(processId);
    const { setShowSaveYourChangesDialog, editorUnsavedChanges, setEditorUnsavedChanges } = useContext(UnsavedChangesContext);

    const [inputValueDict, setInputValueDict] = useState({
        'processName' : `${processName}-Copy`
    } as {[key: string]: string });
    
    const [isOpen, setIsOpen] = useState(false);

    const { onDuplicate, isModule } = props;
    const resourceName = isModule ? 'Module' : 'Process';

    const handleDuplicateClick = React.useCallback(() => {
        setIsOpen(false);
        onDuplicate(inputValueDict['processName']);
    }, [inputValueDict, onDuplicate, setIsOpen]);

    const handleClose = React.useCallback(() => setIsOpen(false), []);

    const handleOpen = React.useCallback(async () => {
        if (editorUnsavedChanges && !processIsReadOnly){
            const shouldContinue = await openDialogAndWaitForInput(setShowSaveYourChangesDialog, setEditorUnsavedChanges);
            if (!shouldContinue) return;
        }

        setIsOpen(true)
        setInputValueDict({
            ...inputValueDict,
            'processName': `${processName}-Copy`
        });
    }, [inputValueDict, processName, setIsOpen, setInputValueDict, editorUnsavedChanges, setEditorUnsavedChanges, setShowSaveYourChangesDialog, processIsReadOnly]);

    const validateCallback = useCallback((val: string) => !!(val && val.length < 100), []);

    const sharingTooltip = hasValidId ? undefined : `Save ${isModule ? 'module' : 'process'} to enable.`;

    return <>
    <Tooltip content={sharingTooltip}>
        <Button icon={IconNames.DUPLICATE}  onClick={handleOpen} text={'Duplicate'} {...props.buttonProps} disabled={!hasValidId}/>
    </Tooltip>
    <Dialog isOpen={isOpen} onClose={handleClose} title={`Duplicate ${resourceName}`}>
        <div className={Classes.DIALOG_BODY}>
            <Input
                key={'processName'}
                id={'processName'}
                inputValueDictState={[inputValueDict, setInputValueDict]}
                label={`${resourceName} Name`}
                inputGroupProps={{
                    value: inputValueDict['processName']
                }}
                warningText={`${resourceName} name is required and must be under 100 characters.`}
                warningTitle={`${resourceName} name is required.`}
                validation={validateCallback}
            />
            <Row style={{ margin: "0 20px" }}>
                <Button style={{margin: 'auto 5px auto auto'}} onClick={handleClose}>Cancel</Button>
                <Button style={{margin: 'auto 0px auto 0px'}}  intent={Intent.SUCCESS} onClick={handleDuplicateClick}>Duplicate {resourceName}</Button>
            </Row>
        </div>
    </Dialog>
    </>
    
}