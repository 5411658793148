import { ChevronLeft } from '@mui/icons-material';
import { Container, Typography, Button, Box, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { WhyJoinFabuBlox } from './WhyJoinFabuBlox';
import { ApplicationDialog } from './ApplicationDialog';

export interface KeyInformationItem {
  label: string;
  value: string;
}

interface JobPostingProps {
    children: React.ReactNode;
    jobTitle: string;
    keyInformationItems: KeyInformationItem[];
  }

export const KeyInformation = ({ items }: { items: KeyInformationItem[] }) => (
  <div className="tw-space-y-4">
    {items.map((item, index) => (
      <Box key={index}>
        <Typography
          variant="caption"
          className="tw-text-gray-500 tw-uppercase tw-font-medium"
        >
          {item.label}
        </Typography>
        <Typography variant="body1" className="tw-text-gray-800 tw-mt-1">
          {item.value}
        </Typography>
        {index < items.length - 1 && <Divider className="tw-my-3 tw-border-gray-300" />}
      </Box>
    ))}
  </div>
);
  
  const JobPosting = ({ 
    jobTitle,
    children,
    keyInformationItems }: JobPostingProps) => {
    const history = useHistory();
  
    return (
      <Box
        className="tw-overflow-y-auto tw-h-screen tw-bg-[#B3B3B3]/10"
        sx={{ padding: "0 1rem" }} // Add scrollable container
      >
        <Container
          maxWidth="md"
          className="tw-my-12 tw-py-8 tw-overflow-hidden"
          sx={{
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          <Button
            startIcon={<ChevronLeft />}
            onClick={() => history.push("/careers")}
            className="tw-mb-6 tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100 tw-rounded-md tw-transition-colors tw-duration-200 tw-ease-in-out"
          >
            Back to Hiring
          </Button>
          <div className="tw-w-full">
            <Typography
              variant="h4"
              className="tw-font-bold tw-mb-6 tw-text-center lg:tw-text-left"
            >
              {jobTitle}
            </Typography>
  
            <div className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-gap-8">
              {/* Key Information in its own column */}
              <div className="tw-hidden lg:tw-block lg:tw-w-1/4">
                <div className="tw-mb-8">
                  <KeyInformation items={keyInformationItems} />
                </div>
              </div>
              {/* Main Content */}
              <div className="tw-w-full lg:tw-w-3/4">
                {children}  
                <ApplicationDialog />
              </div>
            </div>
          </div>
        </Container>
      </Box>
    );
  };
  
  export default JobPosting;
