import { Typography } from "@mui/material";
/**
 * Why work at FabuBlox?
 *
 * Semiconductor fabrication and nanotechnology are foundational to modern high-tech innovation, powering everything from
 * cutting-edge sensors to AI chips. Despite their critical role, the software tools these industries rely on for
 * communication, process management, and collaboration remain outdated, inefficient, and disconnected.
 *
 * FabuBlox is here to change that.
 *
 * Our cloud-based platform connects semiconductor designers, fabs, and equipment providers, streamlining the entire
 * nanofabrication process. Think of it as a collaborative hub, where designing and fabricating semiconductor devices
 * becomes faster, smarter, and more efficient. Designers can match their needs to fab capabilities, while fabs can
 * attract more customers and automate manufacturing cycles.
 *
 * You'll help design the highly-accessible visualizations, simulations, and real-time collaboration tools, that empower
 * engineers and researchers. The FabuBlox data-standard provides unparalleled opportunities for unique AI and ML models.
 *
 * If you re passionate about modernizing how the high-tech world operates and eager to work on a platform that supports
 * scientific innovation, join us in shaping our company.
 */
export const AboutFabuBlox = () => (
    <>
    <Typography variant="h6" className="tw-mt-8 tw-tracking-tightest">
      Why work at FabuBlox?
    </Typography>
    <Typography className="tw-tracking-wide">
      <p>
        <strong>Semiconductor fabrication</strong> and <strong>nanotechnology</strong> are foundational to modern high-tech innovation, powering everything from <strong>cutting-edge sensors</strong> to <strong>AI chips</strong>. Despite their critical role, the software tools these industries rely on for communication, process management, and collaboration remain outdated, inefficient, and disconnected.
      </p>
      <p>
      <strong>FabuBlox</strong> is here to change that.
      </p>
      <p>
        Our cloud-based, software platform connects semiconductor designers, fabs, and equipment providers, streamlining the entire nanofabrication process. Think of it as a collaborative hub, where designing and fabricating <strong>semiconductor devices</strong> becomes faster, smarter, and more efficient.  Designers can match their needs to fab capabilities, while fabs can attract more customers and automate manufacturing cycles.
      </p>
      <p>
        You'll help design the highly-accessible <strong>visualizations, simulations,</strong> and <strong>real-time collaboration tools</strong>, that empower engineers and researchers. The FabuBlox data-standard provides unparalleled opportunities for unique <strong>AI</strong> and <strong>ML</strong> models.
      </p>
      <p>
        If you’re passionate about modernizing how the high-tech world operates and eager to work on a platform that supports scientific innovation, join us in shaping our company.
      </p>
    </Typography></>
  );

  