import * as React from 'react';
import { Container } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { fabutheme } from '../MaterialUI/muicolortheme';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { useHistory } from 'react-router-dom';
import { Column } from '../Layout/layouts';

export const HiringBanner: React.FC = () => {

  const history = useHistory()
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={fabutheme}>
        <div className='tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-to-[#ff9567] tw-z-10'>
          <Container maxWidth="xl"> 
            <Column>
            <div className='tw-h-6 hover:tw-h-10 tw-flex tw-items-center tw-justify-center tw-animate-pulse tw-transition-all tw-duration-300'>
                <WorkOutlineOutlinedIcon className='tw-hidden sm:tw-flex tw-text-white tw-mr-2 tw-text-lg '/>
                <a className='
                tw-text-white tw-text-[10px] sm:tw-text-sm tw-font-semibold tw-outline-none 
                hover:tw-no-underline hover:tw-italic' onClick={() => history.push('/careers')}>  
                    We're hiring! Click to learn more about our openings
                </a>
                <WorkOutlineOutlinedIcon className='tw-hidden sm:tw-flex tw-text-white tw-ml-2 tw-text-lg '/>
            </div>
            </Column>
          </Container>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
