import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Container, Button } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { fabutheme } from '../MaterialUI/muicolortheme';

export const ProcessEditorInfo: React.FC = () => {
  const fabuKnowRef = useRef<HTMLDivElement>(null);
  const collaborativeRef = useRef<HTMLDivElement>(null);
  const privacyRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLAnchorElement>(null);
  const line = useRef<HTMLDivElement>(null);

  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);

  const [smoothedCursorX, setSmoothedCursorX] = useState(0);
  const [smoothedCursorY, setSmoothedCursorY] = useState(0);

  const animationFrameId = useRef<number | null>(null);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setCursorX(event.clientX);
      setCursorY(event.clientY);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const smoothingFactor = 0.001; 

    const animate = () => {
      setSmoothedCursorX((prevX) => prevX + (cursorX - prevX) * smoothingFactor);
      setSmoothedCursorY((prevY) => prevY + (cursorY - prevY) * smoothingFactor);
      animationFrameId.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [cursorX, cursorY]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={fabutheme}>
        <div id="ProcessEditor" className='tw-h-94svh tw-bg-gradient-to-b'>
        <div className=''>
        <div className="tw-top-0 tw-z-[-2] tw-bg-[#181818] tw-bg-opacity-100 revDotTrace-line" 
             style={{ 
               '--gradient-size': '3px', 
               backgroundPosition: `${smoothedCursorX - 16}px ${smoothedCursorY - 16}px`
             } as React.CSSProperties}>
          <div className="
          tw-top-0 tw-z-[-3] tw-bg-gradient-to-tl tw-from-[transparent]
          tw-via-[#8e4ca89c] tw-to-transparent">
          <div className="tw-top-0 tw-z-[-1] tw-bg-gradient-to-b tw-from-[white] ">
          <Container maxWidth="xl"> 
          <div className="tw-flex tw-justify-center ">
            <div className="tw-pb-8 md:tw-p-4 tw-mb-16">
              <div className="tw-flex tw-flex-col tw-items-left tw-justify-center tw-mt-4 tw-mx-4">
                <div className="tw-mt-8">
                  <div ref={fabuKnowRef} className="
                  tw-text-5xl md:tw-text-6xl lg:tw-text-7xl tw-flex 
                  tw-justify-start tw-font-black tw-text-[#181818] opacity-0">
                    <h5>Design Processes</h5>
                  </div>
                  <div ref={collaborativeRef} className="
                  tw-text-2xl tw-flex tw-mt-4 tw-mb-4 tw-justify-start 
                  tw-text-[#181818] opacity-0 tw-animation-delay-200 ">
                    <h2 className="tw-text-left">
                       Standardized | Automated | Shareable&nbsp;
                    </h2>
                  </div>
                </div>
              </div>
              <div ref={line} className='tw-bg-black tw-w-1/4 tw-justify-start tw-h-0.5 tw-mx-4 tw-mb-4'></div>

              <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-6 tw-justify-center">
                <div ref={privacyRef} className="
                  tw-rounded-xl tw-overflow-hidden tw-top-0 tw-flex 
                  tw-flex-col tw-p-4 opacity-0 tw-animation-delay-400">
                  <h2 className='tw-text-[#181818] tw-text-left tw-text-xl '>The <i>FabuBlox Library</i> provides flexible, standardized steps to use in your process flows. The editor automatically generates cross-sections of your design — any changes update on-the-fly and propogate through the entire process.<br/><br/>Keep your processes private, share them with collaborators, export as a powerpoint, or publish your work to the public <i>FabuBase.</i> </h2>
                  <a ref={buttonRef} href='/process-editor/' target='_blank'>
                  <Button variant="contained" color='warning'size="large" className="tw-my-8">
                    Start Designing
                  </Button>
                </a>                
                  </div>
                <div className="
                tw-flex tw-items-baseline tw-justify-end tw-mb-4 
                md:tw-mb-0 lg:tw-mt-4 tw-animate-fadeIn"
                >
                  <div className="
                  tw-rounded-xl tw-outline tw-outline-[#ff9567] 
                  tw-outline-2 tw-inline-block tw-shadow-[0_60px_60px_-15px_rgba(0,0,0,0.6)] 
                  hover:tw-shadow-[0_60px_60px_-15px_rgba(0,0,0,0.9)] hover:-tw-translate-y-1 
                  hover:tw-outline-4 tw-overflow-clip"
                  >
                    <video 
                      onClick={() => window.open('/process-editor')}
                      src="/process_editor_video.mp4"
                      className=""
                      style={{ 
                        width: '100%',
                        height: '105%',
                      }}
                      controls
                      loop
                    />
                  </div>
                </div>
              </div>
              <div className='tw-flex tw-justify-start tw-mx-8 tw-bottom-10 tw-m opacity-0 tw-animation-delay-800'>                
              </div>
            </div>
          </div>
          </Container>
          </div>
          </div>
        </div>    
        </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
