import { useMemo } from "react";
import { useFabuState } from "../../hooks/state/use-fabu-state";

export const useIsProcessSaved = () => {
    const [processId] = useFabuState('processId');

    const isProcessSaved = useMemo(() => {
        return !(processId === 'new' || processId === 'create' || !processId || processId === '');
    }, [processId]);

    return isProcessSaved;
}