import { Typography } from "@mui/material";
import JobPosting, { KeyInformationItem } from "../../components/Careers/JobPosting";
import { InfoList } from "../../components/Careers/InfoList";
import { WhyJoinFabuBlox } from "../../components/Careers/WhyJoinFabuBlox";

export function MachineLearningEngineerPage() {
    const keyInformationItems: KeyInformationItem[] = [
        {
            label: "Location",
            value: "Boston Office or Hybrid",
        },
        {
            label: "Title",
            value: "Machine Learning / AI Engineer",
        },
        {
            label: "Experience",
            value: "3+ years",
        }
    ];

    const aboutTheRole = (
        <>
            <Typography variant="h6" className="tw-font-semibold">
                About the Role
            </Typography>
            <Typography className="tw-my-4">
                At <strong>FabuBlox</strong>, we are building a unified online platform where nanotechnology companies can design and analyze their devices at the nanoscale. We are seeking a <strong>Machine Learning / AI Engineer</strong> to help develop models that analyze metrology data from fabricated devices, unlocking deeper insights into nanoscale properties and measurements.
            </Typography>
        </>
    );

    const jobSpecs = (
        <>
            <InfoList
                title="Your Responsibilities"
                items={[
                    <>Design and implement <strong>machine learning models</strong> to extract meaningful insights from nanoscale metrology data.</>,
                    "Collaborate with engineering and product teams to integrate ML-powered features into our platform.",
                    <>Develop robust <strong>data pipelines</strong> for pre-processing and augmenting metrology datasets.</>,
                    <>Stay updated on the latest <strong>AI/ML research</strong> relevant to our domain and apply cutting-edge techniques.</>,
                    "Optimize models for accuracy, scalability, and performance in real-world production environments.",
                ]}
            />

            <InfoList
                title="You’ll Succeed in This Role if You..."
                items={[
                    <>Have hands-on experience building and deploying <strong>ML models</strong> using Python and popular ML frameworks (e.g., <strong>PyTorch</strong>, <strong>TensorFlow</strong>).</>,
                    <>Are excited about working with complex <strong>scientific datasets</strong> and extracting actionable insights.</>,
                    "Enjoy solving challenging problems and continuously improving models based on feedback and new data.",
                ]}
            />

            <InfoList
                title="Bonus Points"
                items={[
                    <>Prior experience in a <strong>startup</strong> or founding environment where you helped shape the direction of AI-powered products.</>,
                    <>A track record of applying ML to domains that include <strong>semiconductor research</strong> or <strong>material science</strong>.</>,
                ]}
            />
        </>
    );

    const jobPostingProps = {
        keyInformationItems,
        jobTitle: "Machine Learning / AI Engineer",
    };

    return (
        <JobPosting {...jobPostingProps}>
            {aboutTheRole}
            {jobSpecs}
            <WhyJoinFabuBlox />
        </JobPosting>
    );
}
