import { DialogTitle, DialogContent, Typography, Button, Dialog, DialogActions } from "@mui/material";
import { useState } from "react";
import { InfoList } from "./InfoList";

export function ApplicationDialog() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        className="tw-mt-8 tw-bg-blue-600 tw-text-white tw-px-6 tw-py-2 tw-rounded-full hover:tw-bg-blue-700"
        onClick={handleOpen}
      >
        Apply Now
      </Button>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        className="tw-rounded-lg tw-shadow-lg tw-overflow-hidden"
        maxWidth="sm" 
        fullWidth
      >
        <DialogTitle className="tw-font-bold tw-text-xl tw-text-gray-900 tw-bg-gray-100 tw-py-4 tw-px-6">
          Apply Now
        </DialogTitle>
        <DialogContent className="tw-space-y-6 tw-px-6 tw-py-4">
          <p><Typography className="tw-text-gray-700 tw-text-base">
            Thank you for your interest and we're looking forward to hearing from you. Please include the following in your email:
          </Typography></p>
          <InfoList title="" items={[
            "An introduction or cover letter telling us why you are interested", 
            "Your resume",
            "LinkedIn profile",
            "GitHub link or personal website (if applicable)"]} />
          <Typography className="tw-text-gray-700 tw-text-base">
            Email us at <a href="mailto:jobs@fabublox.com" className="tw-text-indigo-600 tw-font-medium hover:tw-underline">jobs@fabublox.com</a>. Feel free to reach out first with any questions about the role or company.
          </Typography>
        </DialogContent>
        <DialogActions className="tw-bg-gray-100 tw-px-6 tw-py-4">
          <Button 
            onClick={handleClose} 
            className="tw-bg-gray-300 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-md hover:tw-bg-gray-400"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
